import React from 'react'
import { motion } from 'framer-motion'

const TextFlipMotion = ({ children, whichtext }) =>
  whichtext === 'first' ? (
    <motion.div
      style={{
        backfaceVisibility: 'hidden',
        '-webkit-backface-visibility': 'hidden'
      }}
      initial={{ rotateX: 0, x: '-1px' }}
      animate={{
        rotateX: [0, 0, 180, 180, 360],
        x: '-1px'
      }}
      transition={{
        rotateX: {
          duration: 6,
          repeat: 'Infinity',
          times: [0, 0.4, 0.5, 0.9, 1]
        }
      }}
    >
      {children}
    </motion.div>
  ) : (
    <motion.div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        backfaceVisibility: 'hidden',
        '-webkit-backface-visibility': 'hidden'
      }}
      initial={{ rotateX: 180, x: '-1px' }}
      animate={{
        rotateX: [180, 180, 360, 360, 540],
        x: '-1px'
      }}
      transition={{
        rotateX: {
          duration: 6,
          repeat: 'Infinity',
          times: [0, 0.4, 0.5, 0.9, 1]
        }
      }}
    >
      {children}
    </motion.div>
  )

export default TextFlipMotion
