import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

const SectionGrid = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: theme.spacing(7),
  '@media (max-width:1200px)': {
    rowGap: theme.spacing(6)
  },
  '@media (max-width:900px)': {
    rowGap: theme.spacing(5)
  },
  '@media (max-width:600px)': {
    rowGap: theme.spacing(4)
  }
}))

export default SectionGrid
