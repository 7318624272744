import { styled } from '@mui/material/styles'
import Divider from '@mui/material/Divider'

const StyledDivider = styled(Divider)(({ theme }) => ({
  align: 'center',
  borderColor: theme.palette.secondary.main,
  width: '120px',
  borderBottomWidth: '3px',
  margin: '0 auto',
  '@media (max-width:1200px)': { width: '110px' },
  '@media (max-width:900px)': { width: '100px' },
  '@media (max-width:600px)': { width: '80px' }
}))

export default StyledDivider
