import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

const SecondaryGrid = styled(Grid)(
  ({ theme, reverse, alignstart, aboutUsSection }) => ({
    justifyContent: 'space-between',
    alignItems: alignstart ? 'start' : 'center',
    '@media (max-width:900px)': {
      flexDirection: reverse ? 'column-reverse' : 'column',
      justifyContent: 'center',
      rowGap: aboutUsSection ? theme.spacing(10) : theme.spacing(2)
    },
    '@media (max-width:600px)': {
      rowGap: aboutUsSection && theme.spacing(6)
    }
  })
)

export default SecondaryGrid
