import React, { useState } from 'react'
import TitleBox from '../components/styledComponents/TitleBox'
import WrapperGrid from '../components/styledComponents/WrapperGrid'
import SectionGrid from '../components/styledComponents/SectionGrid'
import SecondaryGrid from '../components/styledComponents/SecondaryGrid'
import ImageBox from '../components/styledComponents/ImageBox'
import TextFlipMotion from '../components/styledComponents/TextFlipMotion'
import {
  MotionSection,
  MotionLogo,
  MotionFaq
} from '../components/styledComponents/SlideMotion'
import StyledDivider from '../components/styledComponents/StyledDivider'
import TopContainer from '../components/styledComponents/TopContainer'
import Link from '../components/Link'
import { boxes } from '../assets/data/homeBoxes'
import { faq } from '../assets/data/faq'
import { logos } from '../assets/data/logos'
import heroBackground from '../assets/images/GEO5_compressed.webp'
import dashboard from '../assets/images/dashboard.png'
import gematriaSearch from '../assets/images/gematriaSearch.png'
import sentiment from '../assets/images/sentiment.png'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const Home = () => {
  const [isBoxOpen, setIsBoxOpen] = useState(false) // Boxes Accordions expanded state. Allowing only one to be expanded at a time
  const [isFaqOpen, setIsFaqOpen] = useState(false) // FAQ Accordions expanded state. Allowing only one to be expanded at a time

  const handleAccordionChange =
    (index, whichAccordion) => (event, isExpanded) => {
      whichAccordion === 'boxes'
        ? setIsBoxOpen(isExpanded ? index : false)
        : setIsFaqOpen(isExpanded ? index : false)
    }
  return (
    <>
      <Box
        sx={(theme) => ({
          background: `url(${heroBackground}) no-repeat center`,
          backgroundSize: 'cover',
          marginBottom: theme.spacing(20),
          '@media (max-width:1200px)': {
            marginBottom: theme.spacing(16)
          },
          '@media (max-width:900px)': {
            marginBottom: theme.spacing(12)
          },
          '@media (max-width:600px)': {
            marginBottom: theme.spacing(9)
          }
        })}
      >
        <TopContainer hero='true'>
          <Stack>
            <Box>
              <Typography
                component='h1'
                variant='h2'
                sx={{ display: 'inline' }}
              >
                Build custom&nbsp;
              </Typography>
              <Box
                sx={{
                  position: 'relative',
                  display: 'inline-block',
                  perspective: '500px'
                }}
              >
                <TextFlipMotion whichtext='first'>
                  <Typography component='h1' variant='h2'>
                    market signals
                  </Typography>
                </TextFlipMotion>
                <TextFlipMotion whichtext='second'>
                  <Typography component='h1' variant='h2'>
                    ESG scores
                  </Typography>
                </TextFlipMotion>
              </Box>
            </Box>
            <Typography component='span' variant='h6'>
              Our no-code engine lets you create custom signals - in minutes,
              not days.
            </Typography>
            <Link
              to='/contact-us'
              sx={{ color: 'white', mt: (theme) => theme.spacing(3) }}
              underline='none'
            >
              <Button
                variant='contained'
                color='secondary'
                disableElevation={true}
              >
                Request My Demo
              </Button>
            </Link>
          </Stack>
        </TopContainer>
      </Box>
      <Box sx={{ position: 'relative' }}>
        <Container>
          <WrapperGrid container>
            {/* Inner sections wrapper grid */}
            <Grid item>
              <MotionSection>
                <Stack justifyContent='center'>
                  <SecondaryGrid alignstart='true' container>
                    {boxes.map((box, index) => (
                      <Grid key={box.cardTitle} item md={5.5}>
                        <Card
                          sx={(theme) => ({
                            borderRadius: '20px',
                            border:
                              isBoxOpen === index &&
                              `2px solid ${theme.palette.secondary.main} `,
                            margin: isBoxOpen === index && '-2px',
                            '&:hover': {
                              border: `2px solid ${theme.palette.secondary.main} `,
                              margin: '-2px'
                            }
                          })}
                        >
                          <Accordion
                            expanded={isBoxOpen === index}
                            onChange={handleAccordionChange(index, 'boxes')}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <CardHeader title={box.cardTitle} />
                            </AccordionSummary>
                            <AccordionDetails>
                              <CardContent>
                                <Typography
                                  variant='h6'
                                  color='error.main'
                                  sx={{ fontWeight: 'bold' }}
                                >
                                  {box.cardSubtitle0}
                                </Typography>
                                <List>
                                  <Typography
                                    variant='body1'
                                    color='text.primary'
                                  >
                                    {box.listTitle0}
                                  </Typography>
                                  {box.listItems0.map((item) => (
                                    <ListItem key={item}>
                                      <ListItemText primary={item} />
                                    </ListItem>
                                  ))}
                                </List>
                              </CardContent>
                              <CardContent>
                                <Typography
                                  variant='h6'
                                  color='success.main'
                                  sx={{ fontWeight: 'bold' }}
                                >
                                  {box.cardSubtitle1}
                                </Typography>
                                <List>
                                  {box.listItems1.map((item) => (
                                    <ListItem key={item}>
                                      <ListItemText primary={item} />
                                    </ListItem>
                                  ))}
                                </List>
                              </CardContent>
                            </AccordionDetails>
                          </Accordion>
                        </Card>
                      </Grid>
                    ))}
                  </SecondaryGrid>
                </Stack>
              </MotionSection>
            </Grid>
            <Grid item>
              <MotionSection>
                <TitleBox>
                  <Typography component='h2' variant='h3' align='center'>
                    Products
                  </Typography>
                  <StyledDivider variant='middle' />
                </TitleBox>
              </MotionSection>
              <SectionGrid container>
                <MotionSection>
                  <SecondaryGrid item container>
                    <Grid item md={5.5}>
                      <ImageBox src={gematriaSearch} component='img' />
                    </Grid>
                    <Grid item md={5.5}>
                      <Box
                        sx={{
                          borderLeft: 'solid 3px',
                          borderLeftColor: 'secondary.main',
                          paddingLeft: '10px'
                        }}
                      >
                        <Typography component='span' variant='h4'>
                          Search Statistics
                        </Typography>
                      </Box>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary='
                      Search a proportion of relevant articles by keyword over time
                      '
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary='
                      Display computed sentiment of articles daily'
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary='
                      Most positively or negatively mentioned companies or entities in those articles'
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary='
                      Customize and fine-tune more statistics and display them
                      in interactive widgets'
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </SecondaryGrid>
                </MotionSection>
                <MotionSection>
                  <SecondaryGrid reverse='true' item container>
                    <Grid item md={5.5}>
                      <Box
                        sx={{
                          borderLeft: 'solid 3px',
                          borderLeftColor: 'secondary.main',
                          paddingLeft: '10px'
                        }}
                      >
                        <Typography component='span' variant='h4'>
                          News Sentiment While Scrolling
                        </Typography>
                      </Box>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary='
                        Instantly see the sentiment tone of an article so you know where to pay attention to
                      '
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item md={5.5}>
                      <ImageBox src={sentiment} component='img' />
                    </Grid>
                  </SecondaryGrid>
                </MotionSection>
                <MotionSection>
                  <SecondaryGrid item container>
                    <Grid item md={5.5}>
                      <ImageBox src={dashboard} component='img' />
                    </Grid>
                    <Grid item md={5.5}>
                      <Box
                        sx={{
                          borderLeft: 'solid 3px',
                          borderLeftColor: 'secondary.main',
                          paddingLeft: '10px'
                        }}
                      >
                        <Typography component='span' variant='h4'>
                          Customizable and Shareable Dashboard
                        </Typography>
                      </Box>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary='
                      Customize your search statistics by sources, sector, company, data and many more
                      '
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary='
                      Save your dashboards'
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary='
                      Share your dashboard seamlessly just by copy-pasting a URL'
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </SecondaryGrid>
                </MotionSection>
              </SectionGrid>
            </Grid>
            <Grid item>
              <MotionSection>
                <TitleBox>
                  <Typography component='h2' variant='h3' align='center'>
                    Our team originates from
                  </Typography>
                </TitleBox>
              </MotionSection>
              <SectionGrid container>
                <Grid
                  item
                  container
                  alignItems='center'
                  justifyContent='center'
                >
                  {logos.map((logo, index) => (
                    <Grid key={index} item xs={2.7} align='center'>
                      <MotionLogo index={index} logo={logo} />
                    </Grid>
                  ))}
                </Grid>
              </SectionGrid>
            </Grid>
            <Grid item>
              <MotionSection>
                <TitleBox>
                  <Typography component='h2' variant='h3' align='center'>
                    FAQ
                  </Typography>
                </TitleBox>
              </MotionSection>
              <Grid container direction='column' rowSpacing={2}>
                {faq.map((el, index) => (
                  <Grid item>
                    <MotionFaq index={index}>
                      <Card
                        sx={(theme) => ({
                          border:
                            isFaqOpen === index &&
                            `2px solid ${theme.palette.secondary.main} `,
                          margin: isFaqOpen === index && '-2px',
                          '&:hover': {
                            border: `2px solid ${theme.palette.secondary.main} `,
                            margin: '-2px'
                          }
                        })}
                      >
                        <Accordion
                          expanded={isFaqOpen === index}
                          onChange={handleAccordionChange(index, 'faq')}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <CardHeader title={el.question} />
                          </AccordionSummary>
                          <AccordionDetails>
                            <CardContent>
                              {el.answer.map((text) => (
                                <>
                                  <Typography
                                    variant='body1'
                                    component='p'
                                    color='text.primary'
                                  >
                                    {text}
                                  </Typography>
                                  {el.answer.indexOf(text) !==
                                    el.answer.length - 1 && <br />}
                                </>
                              ))}
                            </CardContent>
                          </AccordionDetails>
                        </Accordion>
                      </Card>
                    </MotionFaq>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </WrapperGrid>
        </Container>
      </Box>
    </>
  )
}

export default Home
