import React from 'react'
import { motion } from 'framer-motion'
import {
  logoFaqVariants,
  slideVariants
} from '../../assets/data/transitionsVariants'

export const MotionSection = ({ children }) => (
  <motion.div
    variants={slideVariants}
    initial='initial'
    whileInView='whileInView'
    viewport={{ once: true }}
  >
    {children}
  </motion.div>
)

export const MotionLogo = ({ index, logo }) => (
  <motion.img
    variants={logoFaqVariants}
    custom={index}
    initial='initial'
    whileInView='whileInView'
    viewport={{ once: true }}
    src={logo}
    width='60%'
  />
)

export const MotionFaq = ({ children, index }) => (
  <motion.div
    variants={logoFaqVariants}
    custom={index}
    initial='initial'
    whileInView='whileInView'
    viewport={{ once: true }}
  >
    {children}
  </motion.div>
)
