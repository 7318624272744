export const logoFaqVariants = {
  initial: { y: 100 },
  whileInView: (index) => ({
    y: 0,
    transition: { type: 'spring', bounce: 0, delay: index / 10 }
  })
}

export const slideVariants = {
  initial: { y: 100 },
  whileInView: {
    y: 0,
    transition: { type: 'spring', bounce: 0, duration: 1.1 }
  }
}
