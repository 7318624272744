export const boxes = [
  {
    cardTitle: 'For Banks & Asset Managers (ESG/ETF/QIS)',
    cardSubtitle0: 'Problem',
    listTitle0: 'You have subscribed to many ESG data providers, but:',
    listItems0: [
      "You observe divergence across providers' scores for the same company.",
      'The score they provide is like a black-box: hard to interpret.'
    ],
    cardSubtitle1: 'Solution',
    listItems1: [
      'Seamlessly design your own proprietary ESG scores with our no-code pipeline.',
      'You create no-code rules specifying how data is selected, processed, and aggregated into a score.',
      'Visually inspect each data point constituting your final score: why was it selected, which AI model was applied to it, and how did this AI model make its decision.',
      'Can also be used for thematic stock-picking.'
    ]
  },
  {
    cardTitle: 'For Quant Hedge Funds & Data scientists',
    cardSubtitle0: 'Problem',
    listTitle0: 'When you try new alternative datasets, it takes time to:',
    listItems0: [
      'Transform raw data into predictive market signals.',
      'Pre-process and map to internal financial instruments.'
    ],
    cardSubtitle1: 'Solution',
    listItems1: [
      'We simplify the two most time-consuming parts of processing alternative data: data curation, and signal aggregation.',
      'Our dashboards let you inspect data while you query the API, for better intuitions and reducing time-to-insight.',
      'We take care of the mapping to financial instruments: ISIN, BloombergID, OpenFIGI and others.'
    ]
  }
]
