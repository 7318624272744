import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

const WrapperGrid = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  rowGap: theme.spacing(20),
  '@media (max-width:1200px)': {
    rowGap: theme.spacing(16)
  },
  '@media (max-width:900px)': {
    rowGap: theme.spacing(12)
  },
  '@media (max-width:600px)': {
    rowGap: theme.spacing(9)
  }
}))

export default WrapperGrid
